export const pageView = (pagePath: string) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: 'page-view',
    pageRoute: pagePath,
  });
};

export const detailCalculatorFirstClick = () => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: 'DetailCalculatorFirstClick',
  });
};

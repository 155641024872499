import { createTheme } from '@mui/material/styles';
import { deDE, enUS } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { ThemeOptions } from '@mui/material';
import { baseLightColors } from './baseTheme';

export const colors = {
  white: '#ffffff',
  green: '#46cb18',
  green100: '#dcedc8',
  amber500: '#FF8139',

  ink050: '#f3f7ff',
  ink100: '#ecf0ff',
  ink200: '#e2e6ff',
  ink300: '#d2d6f3',
  ink500: '#8e92ad',
  ink400: '#aeb2ce',
  ink600: '#666A83',
  ink800: '#34384f',
  ink700: '#52576f',
  ink900: '#12182d',

  inkA100: '#e9e6fc',
  inkA200: '#e6e2ff',
  inkA400: '#bfb8d6',
  inkA700: '#5c526f',

  corp050: '#daf5f9',
  corp200: '#89e0ed',
  corp400: '#1cc7dd',
  corp500: '#06b5d0',
  corp600: '#16a2c1',
  corp700: '#1d94b0',

  sun: '#FF8139',
  testimonials: '#3e4969',
  red: '#ff3d00',
  ovMagenta: '#A51890',
  secondaryGray: '#6C7889',
};

const themeProps: ThemeOptions = {
  typography: {
    fontSize: 16, // this is the global default
    // "BiryaniLatin-Regular" is name used in main angular application.
    // It has to be here until we stop using embedded components
    fontFamily: ['Biryani', '"Zilla Slab"', 'Inter', 'BiryaniLatin-Regular', 'sans-serif'].join(
      ','
    ),
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.5px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.43,
      letterSpacing: '-0.01em',
    },
    h1: {
      fontSize: '56px',
      letterSpacing: '1.22px',
      fontWeight: 800,
      lineHeight: '69px',

      '@media (max-width:601px)': {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '40px',
      },
      '@media (max-width:400px)': {
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: '40px',
      },
    },
    h2: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '44px',
      letterSpacing: '0.78px',
      '@media (max-width:600px)': {
        fontSize: '28px',
        fontWeight: 800,
        lineHeight: '34px',
      },
    },
    h3: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '40px',

      '@media (max-width:601px)': {
        fontSize: '26px',
        fontWeight: 700,
        lineHeight: '34px',
      },
    },
    h4: {
      fontSize: '28px',
      fontWeight: 600,
      lineHeight: '36px',

      '@media (max-width:601px)': {
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '34px',
      },
    },
    h5: {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '34px',
      '@media (max-width:601px)': {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    h6: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '-0.02em',
    },
    subtitle1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '30px',
      letterSpacing: '0.75px',
    },
    subtitle2: {
      color: baseLightColors.N600,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      letterSpacing: '0.5px',

      '@media (max-width:601px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    subtitle2bold: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '-0.01em',

      '@media (max-width:601px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    subtitle3: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '36px',
    },
    textLight: {
      fontFamily: 'Biryani, Zilla Slab, Inter, BiryaniLatin-Regular',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    text: {
      fontFamily: 'Biryani, Zilla Slab, Inter, BiryaniLatin-Regular',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    textBold: {
      fontFamily: 'Biryani, Zilla Slab, Inter, BiryaniLatin-Regular',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    button: {
      fontWeight: 700,
    },
    overline: {
      fontSize: '14px',
      fontWeight: 800,
      letterSpacing: '2px',
    },
    caption: {
      fontSize: '12px',
    },
  },
  palette: {
    common: {
      black: 'black',
      white: 'white',
      sun: '#FF8139',
    },
    text: {
      primary: colors.ink900,
      secondary: colors.ink600,
      disabled: colors.ink400,
      positive: baseLightColors.G500,
    },
    background: {
      default: colors.white,
      paper: colors.ink050,
      neutralLight: colors.ink050,
    },
    chart: {
      primary: baseLightColors.M400,
      dark: baseLightColors.N800,
    },
    action: {
      active: colors.ink500,
      disabled: colors.ink400,
      disabledBackground: colors.ink050,
    },
    primary: {
      light: '#862574',
      main: '#A51890',
      dark: '#862574',
      contrastText: baseLightColors.N000,
    },
    border: {
      light: baseLightColors.N100,
      main: baseLightColors.N200,
      dark: baseLightColors.N300,
      active: baseLightColors.M400,
    },
    tertiary: {
      lighter: baseLightColors.M200,
      light: baseLightColors.M300,
      main: baseLightColors.M400,
      dark: baseLightColors.M500,
      contrastText: baseLightColors.N000,
    },
    secondary: {
      main: colors.ink900,
      dark: colors.ink800, // button hover; shaded bluey-gray
      contrastText: colors.white, // dark-slate-blue
    },
    error: {
      main: '#ff5e41', // coral
    },
    warning: {
      main: '#fdc003', // marygold
    },
    info: {
      main: '#fdc003',
      dark: '#fdc003',
      contrastText: colors.ink800, // dark-slate-blue
    },
    success: {
      main: '#6db059', // green-blue
    },
    grey: {
      main: baseLightColors.N300,
      dark: baseLightColors.N800,
      50: colors.ink050,
      100: colors.ink100,
      200: colors.ink200, // ink-200
      300: colors.ink100,
      400: colors.ink500,
      500: colors.ink500,
      600: colors.ink600,
      700: colors.ink700,
      800: colors.ink800,
      900: colors.ink900,
      A100: colors.inkA100,
      A200: colors.inkA200,
      A400: colors.inkA400,
      A700: colors.inkA700,
    },
  },
  shape: {
    borderRadius: 11,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '14px',
          lineHeight: 1.43,
          letterSpacing: '-0.01em',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h4',
          h6: 'h4',
          subtitle1: 'p',
          subtitle2: 'p',
          body1: 'p',
          body2: 'p',
          textLight: 'p',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:hover': { backgroundColor: colors.ink050 },
          backgroundColor: colors.ink100,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          '&.Mui-focused': {
            backgroundColor: colors.ink100,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        color: 'grey',
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            borderRadius: 8,
          },
        },
        {
          props: { size: 'large' },
          style: {
            borderRadius: 13,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderWidth: '2px',
            borderColor: colors.ink400,
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            '&:hover': { borderWidth: '2px' },
            borderWidth: '2px',
            borderColor: colors.ink900,
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            '&:hover': { borderWidth: '2px' },
            borderWidth: '2px',
            borderColor: colors.corp600,
          },
        },
      ],
    },
  },
};
export const customThemeWithSpacing = createTheme({ spacing: 4, ...themeProps });

const themeEn = createTheme(themeProps, enUS);
const themeDe = createTheme(themeProps, deDE);
export const onvistaTheme = () => {
  const { i18n } = useTranslation();
  const theme = i18n.language === 'de' ? themeDe : themeEn;

  const dateLocale = i18n.language === 'de' ? deLocale : enLocale;
  return { theme, dateLocale };
};

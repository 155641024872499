import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

export const useGoogleTagManagerClickHandler = (
  userFlowId: string,
  data?: Record<string, string | undefined | null>
) => {
  const router = useRouter();

  const onClick = useCallback(
    (event: MouseEvent) => {
      if (!(event.target instanceof Element)) return;

      const element = event.target.closest('[data-contentid], [data-eventid]');

      if (element) {
        const eventId = element.getAttribute('data-eventid');
        const contentElementId = element.getAttribute('data-contentid');
        const sectionId =
          element.closest('[data-sectionid]')?.getAttribute('data-sectionid') || 'Unknown section';

        const elementText =
          element.textContent ||
          (element as HTMLButtonElement).innerText ||
          element.querySelector('img')?.title;

        window.dataLayer?.push({
          userFlowId,
          workflowId: userFlowId,
          event: contentElementId ? 'content-click' : eventId || 'button-click',
          pageRoute: router.pathname,
          contextId: sectionId,
          ...(contentElementId ? {} : { buttonId: elementText?.toLowerCase() }),
          ...(contentElementId ? { contentElementId } : {}),
          ...data,
        });
      }
    },
    [userFlowId, data]
  );

  useEffect(() => {
    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [onClick]);

  return;
};

import { ThemeOptions } from '@mui/material';

export const baseLightColors = {
  N1000: '#000000',
  N900: '#1B1F22',
  N800: '#263240',
  N700: '#3C4653',
  N600: '#6C7889',
  N500: '#8593A3',
  N400: '#A8B0BD',
  N300: '#C9D0DA',
  N200: '#E4E9F1',
  N100: '#EBEDF5',
  N050: '#F8F8FD',
  N000: '#FFFFFF',

  M500: '#1182A6',
  M400: '#13B0E1',
  M300: '#97D6EA',
  M200: '#CDEBF5',
  M100: '#E8FAFF',

  G500: '#00997D',
  G400: '#0CD297',
  G300: '#85E8CB',
  G200: '#C2F4E5',
  G100: '#E7FAF5',

  O500: '#D6580F',
  O400: '#FF8139',
  O300: '#FFC09C',
  O200: '#FFE1CF',
  O100: '#FFF2EB',

  R500: '#D92400',
  R400: '#F23F1D',
  R300: '#F89F8E',
  R200: '#FCD1C9',
  R100: '#FEECE8',

  Y500: '#F79D16',
  Y400: '#FFC633',
  Y300: '#FFE299',
  Y200: '#FFF1CE',
  Y100: '#FFF9EB',

  P500: '#43408C',
  P400: '#5F5BB4',
  P300: '#AFADD9',
  P200: '#D9D8ED',
  P100: '#EFEFF7',

  B500: '#1246BE',
  B400: '#2563F3',
  B300: '#92B1F9',
  B200: '#CBDAFC',
  B100: '#E9EFFE',

  // @deprecated all colors below are deprecated
  black: '#000000',
  white: '#ffffff',
  amber500: '#ffc107',

  inkDark: '#141825',
  ink050: '#f3f7ff',
  ink100: '#ecf0ff',
  ink200: '#e2e6ff',
  ink300: '#d2d6f3',
  ink400: '#aeb2ce',
  ink500: '#8e92ad',
  ink600: '#666A83',
  ink800: '#34384f',
  ink850: '#192037',
  ink870: '#2c3045',
  ink700: '#52576f',
  ink900: '#12182d',

  inkA100: '#e9e6fc',
  inkA200: '#e6e2ff',
  inkA400: '#bfb8d6',
  inkA700: '#5c526f',

  corp050: '#daf5f9',
  corp200: '#89e0ed',
  corp400: '#1cc7dd',
  corp500: '#06b5d0',
  corp600: '#16a2c1',
  corp700: '#1d94b0',

  sun: '#fcc536',
  green: '#98c05c',
  lightGreen900: '#33691e',
  lightRed900: '#ff3d00',
  lightGreen100: '#dcedc8',
  lightRed100: '#ffccbc',
  deepOrange900: '#bf360c',
  offWhite: '#f1f8e9',
  searchHighlight: '#bbdefb',

  sand: '#dac981',
  dust: '#f5f3f0',
  desert: '#aa9a7f',
  ice: '#ebf2f0',
  paleBlue: '#e7f0f5',
  beige: '#e3e4e4',
  cursedGrey: '#666666',
  babyBlue: '#609dba',
  cultured: '#f4f4f4',
  iron: '#d8dde0',
  hitGray: '#9ca9b0',
  porcelain: '#edf0f2',

  chartGradient: '#ecf0ff',
  skeleton: '#ecf0ff',
  skeletonHighlight: '#d2d6f3',
  positiveValue: '#00997D',
  positiveValueBackground: '#E7FAF5',
  negativeValue: '#D92400',
  negativeValueBackground: '#FEECE8',
};

export const baseLightTheme: ThemeOptions = {
  spacing: 4,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '14px',
          lineHeight: 1.43,
          letterSpacing: '-0.01em',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-selected': {
            color: baseLightColors.M500,
          },
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 500,
          minWidth: 'auto',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:focus-within': {
              backgroundColor: baseLightColors.M100,
            },
            backgroundColor: baseLightColors.M100,
            '&:hover': {
              backgroundColor: baseLightColors.M100,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-endAdornment': {
            position: 'initial',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: baseLightColors.N300,
            },
          },
          '&:focus-within': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: baseLightColors.M400,
            },
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: baseLightColors.M400,
          },
        },
        notchedOutline: {
          borderColor: baseLightColors.N200,
        },
      },
    },
  },
};

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://ab71c362016e472a9dc0e43c6ab2691a@o393176.ingest.sentry.io/5241849',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production',
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
});

import { AppProps } from 'next/app';
import { ThemeProvider, Theme } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import 'keen-slider/keen-slider.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../components/i18n';
import React, { ReactNode, useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'leaflet/dist/leaflet.css';
import Router from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { EmotionCache } from '@emotion/cache';
import { pageView } from '../src/helpers/gtag';
import { onvistaTheme } from '../plugins/material-ui/themes/onvistaTheme';
import createEmotionCache from '../helpers/createEmotionCache';
import '../styles/global.css';
import { USER_FLOW_ID } from '../src/helpers/constants';
import { useGoogleTagManagerClickHandler } from '../src/hooks/useGoogleTagManagerClickHandler';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();
const clientSideEmotionCache = createEmotionCache();

export const AppProvider = ({
  children,
  emotionCache,
}: {
  children: ReactNode;
  emotionCache: EmotionCache;
}) => {
  const { theme } = onvistaTheme();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  );
};

interface Props extends AppProps {
  emotionCache: EmotionCache;
}

function App({ Component, emotionCache = clientSideEmotionCache, pageProps }: Props) {
  useEffect(() => {
    // Remove the server-side injected CSS; see https://github.com/mui-org/material-ui/tree/master/examples/nextjs
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url);
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useGoogleTagManagerClickHandler(USER_FLOW_ID);

  return (
    <AppProvider emotionCache={emotionCache}>
      <Component {...pageProps} />
    </AppProvider>
  );
}

export default App;
